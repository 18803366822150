import { CommonModule } from '@angular/common';
import { Component, inject, input, model, OnDestroy, OnInit, output, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LinkedObjectsButtonComponent } from '@ui/common';
import { ButtonRectangleComponent } from '@ui/common/buttons';
import { CheckboxComponent } from '@ui/common/forms';
import { BaseModalComponent } from '@ui/common/modals';
import { untilDestroyed } from 'angular-v2-utils';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxTippyModule, NgxTippyProps, NgxTippyService } from 'ngx-tippy-wrapper';
import { map } from 'rxjs';

@Component({
  selector: 'ui-apartment-picker',
  standalone: true,
  imports: [
    CommonModule,
    BaseModalComponent,
    ButtonRectangleComponent,
    CheckboxComponent,
    LinkedObjectsButtonComponent,
    NgxTippyModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './apartment-picker.component.html',
  styleUrl: './apartment-picker.component.scss',
})
export class ApartmentPickerComponent implements OnInit, OnDestroy {
  readonly tippyService = inject(NgxTippyService);
  objects = input<{ value: string; label: string }[]>([]);
  selected = input<string[]>([]);
  loading = input<boolean>(false);
  small = input<boolean>(true);
  id = input.required<string>();
  counter = model<number>(0);
  pending = signal<boolean>(false);
  roomIds = signal<string[]>([]);
  save = output<string[]>();

  form: FormGroup = new FormGroup({
    selectAll: new FormControl(false),
    rooms: new FormGroup({}),
  });

  linkedObjectsTippyConfig: NgxTippyProps = {
    arrow: true,
    theme: 'dropdown',
    maxWidth: '400px',
    placement: 'bottom-start',
    offset: [0, 5],
    allowHTML: true,
    interactive: true,
    interactiveBorder: 50,
    trigger: 'click',
    animation: 'scale',
  };

  getRoomControlAt(object: { value: string; label: string }): FormControl {
    return this.form.get('rooms')?.get(object.value) as FormControl;
  }

  onCancel() {
    this.tippyService.hide(`linked-objects-${this.id()}`);
  }

  onSave() {
    this.tippyService.hide(`linked-objects-${this.id()}`);
    this.save.emit(this.roomIds());
    this.#updateCounter(this.selected().length);
  }

  ngOnInit() {
    this.#generateFormFields();
    this.form
      .get('rooms')
      ?.valueChanges.pipe(
        map(rooms => Object.keys(rooms).filter(k => rooms[k])),
        untilDestroyed(this),
      )
      .subscribe(ids => {
        this.roomIds.set(ids);
        this.#updateCounter(this.selected().length);
      });
    this.form
      .get('selectAll')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe(state => {
        this.objects().forEach(obj => {
          this.form.get('rooms')?.get(obj.value)?.setValue(state, {
            eventEmit: false,
          });
        });
      });
  }

  ngOnDestroy() {
    console.log();
  }

  #generateFormFields(): void {
    this.objects().forEach(obj => {
      (this.form.get('rooms') as FormGroup).addControl(
        obj.value,
        new FormControl(this.selected().includes(obj.value)),
        {
          emitEvent: false,
        },
      );
    });
    this.roomIds.set(this.selected());
  }

  #updateCounter(count: number) {
    this.counter.set(count);
  }
}
